import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getProducts = createAsyncThunk("products/get", async () => {
  const response = await api.get(`api/products`)
  return {
    data: response.data
  }
})

/**
 * @typedef {Object} Product
 * @property {number} value
 * @property {string} label
 */

/**
 * @typedef {Object} ProductsState
 * @property {Product | null} productSelected
 * @property {Product[]} products
 */

/**
 * @type {ProductsState}
 */
const initialState = {
  productSelected: null,
  products: []
}

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.productSelected = action.payload
    },
    setProductById: (state, action) => {
      const searchValue = parseInt(action.payload)
      const products = state.products

      const product = products.filter((product) => {
        return product.value === searchValue
      })

      if (product.length) {
        state.productSelected = product[0]
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload.data

      if (!state.productSelected && state.products.length > 0) {
        state.productSelected = state.products[0]
      }
    })
  }
})

export const { setProduct, setProductById } = productSlice.actions
export default productSlice.reducer
