// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { Cert } from '../assets/images/icons'

export const modalTypeRenew = createSlice({
  name: 'modals_custom',
  initialState: {
    toggle: false,
    loading: false
  },
  reducers: {
    showModalRenew: (state) => {
      state.toggle = true
      state.loading = false
    },
    hideModalRenew: (state) => {
      state.toggle = false
      state.loading = false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { showModalRenew, hideModalRenew, setLoading } = modalTypeRenew.actions

export default modalTypeRenew.reducer
