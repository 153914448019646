// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"


export const getTaxDatById = createAsyncThunk(
  "taxData/getById",
  async (id) => {
    const response = await api.get(`api/taxdata/${id}`)
    return {
      data: response.data
    }
  }
)

export const transferTaxData = createAsyncThunk('taxData/transfer', async ({params, toggle}, {dispatch}) => {
  const response = await api.post(`api/taxdata/fransfer`, params)
  if (response.data.status === 'success') {
    toggle() // hide previous modal
    dispatch(showModalSuccess({message: response.data.message, url: '/users/clients'})) // show success modal
  }
})

export const taxDataSlice = createSlice({
  name: "clients",
  initialState: {
    data: null,
    total: 0,
    defaultValues: null,
    transfer_status: 'idle'
  },
  reducers: {
    resetTaxData: (state) => {
      state.data = null
      state.total = 0
      state.defaultValues = null
      state.transfer_status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTaxDatById.fulfilled, (state, action) => {
      state.data = action.payload.data.data[0]
      const payload = action.payload.data.data[0]
      state.defaultValues = {
        procedencia: payload.origin,
        billing_type: payload.type,
        razon_social: payload.business_name,
        nombres: payload.names,
        primer_apellido: payload.last_name,
        segundo_apellido: payload.last_name_2,
        pais: payload.country.value,
        estado: payload.state.value,
        ciudad: payload.city,
        codigo_postal: payload.postal_code,
        colonia: payload.suburb,
        calle_numero: payload.address,
        tax_id: payload.taxid,
        rfc: payload.taxid,
        // cuota_base: "27%", // TODO move this value to config file
        billing: payload.billing_type,
        periodo_corte: payload.billing_period,
        dia: payload.billing_day,
        uso_cfdi: payload.uso_cfdi.value,
        client_id: payload.client_id
      }
    })
    builder.addCase(transferTaxData.pending, (state) => {
      state.transfer_status = 'loading'
    })
    builder.addCase(transferTaxData.fulfilled, (state) => {
      state.transfer_status = 'success'
    })
    builder.addCase(transferTaxData.rejected, (state) => {
      state.transfer_status = 'error'
    })
  }
})

export const { resetTaxData } = taxDataSlice.actions
export default taxDataSlice.reducer
