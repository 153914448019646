// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import fileDownload from 'js-file-download'

export const updateTaxAddress = createAsyncThunk(
  "taxData/updateAddress",
  async ({ id, payload }) => {
    const response = await api.put(`api/taxdata/${id}`, payload)
    return {
      data: response.data
    }
  }
)

export const getWizardInfo = createAsyncThunk(
  "wizard/get",
  async ({ workflow }) => {
    // console.debug("workflow", workflow)
    const response = await api.get(`api/wizard_save/${workflow}`)
    return {
      data: response.data
    }
  }
)

export const getEnsuredEmployeesByCertificate = createAsyncThunk(
  "ensuredEmployees/get",
  async ({ certificate, params = null }) => {
    if (certificate) {
      const response = await api.get(`api/certificates/ensured_employees/${certificate}`, { params })
      return {
        data: response.data,
        params
      }
    }
    return {
      data: [],
      params
    }
  }
)

export const massiveDownload = createAsyncThunk(
  "all/download",
  async ({ certificate, params = {}, filename }) => {
    await api.get(`api/certificate/${certificate}/download/all`, { responseType: 'blob', params })
      .then(res => {
        if (params?.employees?.length === 1) {
          fileDownload(res.data, `${filename}.pdf`)
        } else {
          fileDownload(res.data, filename)
        }
      })
  })

export const downloadCertificateEmployee = createAsyncThunk(
  "ensuredEmployees/download",
  async ({ certificate, employee, filename }) => {
    await api.get(`api/certificate/${certificate}/download/employee`, { responseType: 'blob', params: { employee_id: employee } })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
)

const initialState = {
  selectedCerts: [],
  step: 1,
  response: null,
  data: [], // data from backend
  selectedCoverages: [],
  employees: [],
  suggestions: [], // employees
  sending: false,
  renew: null,
  newCert: {
    keep: 0,
    ensured: null,
    cert: null,
    issuance: null,
    resume: null,
    billing: null
  },
  currentPage: 1,
  params: null,
  meta: {},
  total: 0
}

export const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    selectCerts: (state, action) => {
      const selectedCerts = state.selectedCerts
      if (!selectedCerts.includes(action.payload)) {
        selectedCerts.push(action.payload)
      } else {
        selectedCerts.splice(selectedCerts.indexOf(action.payload), 1)
      }
      state.selectedCerts = selectedCerts
    },
    selectCoverage: (state, action) => {
      const selectedCoverages = state.selectedCoverages
      if (!selectedCoverages.includes(action.payload)) {
        selectedCoverages.push(action.payload)
      } else {
        selectedCoverages.splice(selectedCoverages.indexOf(action.payload), 1)
      }
      state.selectedCoverages = selectedCoverages
    },
    selectCoverages: (state, action) => {
      // console.log('selectCoverages:', action.payload)
      if (action.payload && action.payload.data) {
        const selectedCoverages = action.payload.data.map((coverage) => coverage.id)
        state.selectedCoverages = selectedCoverages
      }
    },
    addWizardInfo: (state, action) => {
      state.newCert = { ...state.newCert, ...action.payload }
    },
    nextStep: (state) => {
      state.step = 1 + state.step
    },
    setKeep: (state, action) => {
      state.newCert.keep = action.payload
    },
    clearWizard: (state) => {
      state.keep = 0
      state.step = 1
      state.response = null
      state.data = [] // data from backend
      state.selectedCoverages = []
      state.newCert = {
        ensured: null,
        cert: null,
        issuance: null,
        resume: null,
        billing: null
      }
    },
    saveCertRenew: (state) => {
      // console.log("STATE", state)
      state.renew = 1
    },
    resetWizard: initialState,
    setEmployees: (state, action) => {
      console.debug('action', action)
      state.employees = action.payload.employees
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWizardInfo.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    builder.addCase(getEnsuredEmployeesByCertificate.fulfilled, (state, action) => {
      if (action.payload?.params?.format === 'suggestions') {
        state.suggestions = action.payload.data
      } else if (action.payload?.params?.page) {
        state.employees = action.payload.data
        state.currentPage = action.payload.data.meta.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.meta.total
      } else {
        state.employees = action.payload.data.data
      }

    })
    builder.addCase(massiveDownload.pending, (state) => {
      state.sending = true
    })
    builder.addCase(massiveDownload.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(massiveDownload.fulfilled, (state) => {
      state.sending = false
    })
  }
})

export const {
  addWizardInfo,
  nextStep,
  selectCoverage,
  selectCoverages,
  clearWizard,
  selectCerts,
  setKeep,
  setStep,
  saveCertRenew,
  resetWizard,
  setEmployees
} = wizardSlice.actions

export default wizardSlice.reducer
