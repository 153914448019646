// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getSellers = createAsyncThunk("sellers/get", async (params) => {
    const response = await api.get(`api/sellers/all/list`, {params})
    return {
      params,
      data: response.data.map((seller) => {
          return {
            value: seller.value,
            label: seller.label,
            role: seller.role_id
          }
      })
    }
})

export const wizardSlice = createSlice({
    name: "sellers",
    initialState: {
        data: [], // data from backend
        response: [],
        modifyCert: {
          netPremium: 0
        }
    },
    reducers: {
      //
    },
    extraReducers: (builder) => {
      builder.addCase(getSellers.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
    }
})

export const { addWizardInfo } = wizardSlice.actions
  
export default wizardSlice.reducer