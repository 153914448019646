// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
// import api from "@src/api/config"


export const FiltersSlice = createSlice({
  name: "filters",
  initialState: {
    type: "folio",
    search: "",
    orderBy: "",
    sort: "",
    filter_counter: 0,
    resetFilters: false
  },
  reducers: {
    setType: (state, action) => {
      // console.debug("action setType", action)
      state.type = action.payload.type
    },
    setSearch: (state, action) => {
      state.search = action.payload.search
    },
    incrementFiltercounter: (state) => {
      state.filter_counter = 1 + state.filter_counter
    },
    decrementFiltercounter: (state) => {
      state.filter_counter = state.filter_counter - 1
    },
    cleanFiltercounter: (state) => {
      state.filter_counter = 0
    },
    setFilterCounter: (state, action) => {
      state.filter_counter = action.payload.value
    },
    toggletResetFilters: (state) => {
      state.resetFilters = !state.resetFilters
    }
  }
})

export const { setType, setSearch, incrementFiltercounter, decrementFiltercounter, cleanFiltercounter, setFilterCounter, toggletResetFilters} = FiltersSlice.actions
export default FiltersSlice.reducer
