import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import api from "@src/api/config";
import {
  CreateClientParams,
  CreateClientResponse,
  GetClientByIdResponse,
  GetClientsResponse,
  ProjectProfile,
  ProjectProfileBody,
  ProjectProfilesResponse,
  ResponseStatusEnum,
  UpdateClientParams,
  UpdateClientResponse,
  UpdateScheduledInvoiceByClientParams,
  UpdateScheduledInvoiceByClientResponse
} from "./clients.types";
import { AppDispatch, RootState } from "../store";
import { setLoading, setNotLoading } from "../response";
import { showModalSuccess } from "../modalTypeSuccess";
import { getMe } from "../auth";
import { RoleEnum } from "@src/custom-types";
import { toast } from "react-toastify";
import { DangerToast } from "@src/components/common/toast/ErrorToast";

export const getSellerClients = createAsyncThunk<
  GetClientsResponse,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("seller/getClients", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const response = await api.get(`api/clients`, {
    params: {
      ...params,
      product: products.productSelected.value,
      business_line: businessLines.businessLineSelected.id
    }
  });
  return {
    params,
    data: response.data
  };
});

export const getClientById = createAsyncThunk<
  GetClientByIdResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("seller/getClientById", async (id: string) => {
  const response = await api.get(`api/clients/${id}`);
  return response.data.data as unknown as GetClientByIdResponse;
});

export const createClient = createAsyncThunk<
  CreateClientResponse,
  CreateClientParams,
  { dispatch: AppDispatch; state: RootState }
>("seller/createClient", async (params, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const response = await api.post(`api/clients`, {
    ...params,
    product_selected: products.productSelected.value,
    business_line_selected: businessLines.businessLineSelected.id
  });

  return {
    status: response.data.status,
    data: response.data.data,
    message: response.data.message
  };
});

export const updateClient = createAsyncThunk<
  UpdateClientResponse,
  UpdateClientParams,
  { dispatch: AppDispatch; state: RootState }
>("updateInfoClient", async (params, thunkApi) => {
  const { id, data, url, action } = params;
  const { dispatch, getState } = thunkApi;
  const state = getState();
  const {
    auth: { userData }
  } = state || { auth: { userData: null } };
  const { role } = userData || {};
  const roleCasted = role as RoleEnum;
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  try {
    dispatch(setLoading(true));
    const response = await api.put(`api/clients/${id}`, {
      ...data,
      product_selected: products.productSelected.value,
      business_line_selected: businessLines.businessLineSelected.id
    });
    dispatch(setNotLoading());
    if (response.data.status === "success") {
      dispatch(
        showModalSuccess({ message: response.data.message, url, action })
      );
      // dispatch(getClients({ page: 1, filter: "active", type: "nombre" }));
      if (roleCasted === RoleEnum.CLIENT) {
        dispatch(getMe());
      }
    }
    return {
      message: response.data.message,
      status: response.data.status as ResponseStatusEnum
    };
  } catch (err) {
    dispatch(setNotLoading());
    console.log("Error:", err);
    toast(<DangerToast message={"Error al actualizar al cliente"} />, {
      icon: null,
      hideProgressBar: true,
      className: "toast-danger",
      autoClose: 5000
    });
    return {
      message: "Error al actualizar al cliente",
      status: ResponseStatusEnum.error
    };
  }
});

export const getProjectProfile = createAsyncThunk<
  ProjectProfilesResponse,
  ProjectProfileBody,
  { dispatch: AppDispatch; state: RootState }
>("seller/getProjectProfile", async (params) => {
  const response = await api.get(`api/clients/project-profile`, { params });
  return {
    data: response.data.data as unknown as ProjectProfile[],
    status: ResponseStatusEnum.success
  };
});

export const updateScheduledInvoiceByClient = createAsyncThunk<
  UpdateScheduledInvoiceByClientResponse,
  UpdateScheduledInvoiceByClientParams,
  { dispatch: AppDispatch; state: RootState }
>(
  "clients/updateScheduledInvoice",
  async ({ clientId, data, toggleHandler }) => {
    const response = await api.put(
      `api/certificates/scheduled/${clientId}`,
      data
    );
    if (response.data.status === "success") {
      toggleHandler();
    }
    return {
      status: response.data.status as ResponseStatusEnum,
      data: response.data
    };
  }
);
