// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalSessionExpired = createSlice({
    name: 'modalSessionExpired',
    initialState: {
        toggle: false
    },
    reducers: {
        showLogoutModal: (state) => {
            state.toggle = true
        },
        hideLogoutModal: (state) => {
            state.toggle = false
        }
    }
})

export const { showLogoutModal, hideLogoutModal } = modalSessionExpired.actions

export default modalSessionExpired.reducer
