import { const_products_id_type, const_products_type } from "./constants"


// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // TODO update these lines to manage dashboard by roles

  if (userRole === 'admin') return '/'
  // if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export function generateDocumentName(docName = "Documento", fileExtension = '') {
  const date = new Date()
  const formatNumber = (num) => num.toString().padStart(2, '0')
  const year = date.getFullYear()
  const month = formatNumber(date.getMonth() + 1)
  const day = formatNumber(date.getDate())
  const hours = formatNumber(date.getHours())
  const minutes = formatNumber(date.getMinutes())
  const seconds = formatNumber(date.getSeconds())
  const fileName = `${docName}-${year}${month}${day}-${hours}${minutes}${seconds}.${fileExtension}`
  return fileName
}
export const getProductType = (product) => {
  if (!product) return null
  if (const_products_id_type.LOADS.includes(product)) return const_products_type.LOADS
  if (const_products_id_type.ELECTRONIC_EQUIPMENT.includes(product)) return const_products_type.ELECTRONIC_EQUIPMENT
  if (const_products_id_type.RC_CONTRACTORS.includes(product)) return const_products_type.RC_CONTRACTORS
  if (const_products_id_type.PERSONAL_ACCIDENTS.includes(product)) return const_products_type.PERSONAL_ACCIDENTS
  if (const_products_id_type.ORBE_360.includes(product)) return const_products_type.ORBE_360
  return ""
}

export function calculateDownloadTime(quantity) {
  const batches = Math.ceil(quantity / 30)
  const timePerBatch = 58
  const totalTimeSeconds = batches * timePerBatch
  const totalTimeMinutes = (totalTimeSeconds / 60).toFixed(2)

  return totalTimeMinutes
}