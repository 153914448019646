// ** Redux Imports
// path: @src/views/Catalogs/Plans/store
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

export const getPlans = createAsyncThunk("getPlans", async (params) => {
  const response = await api.get(`api/plans`, {params})
  return {
    params,
    data: response.data
  }
})

export const getPlansById = createAsyncThunk("plans/getById", async (planId) => {
  // console.log(planId)
  const response = await api.get(`api/plans/${planId}`)
  return {
    data: response.data
  }
})

export const getSuggestions = createAsyncThunk("getSuggestions", async () => {
  const response = await api.get(`api/plans/suggestions`)
  return {
    data: response.data
  }
})

export const createPlan = createAsyncThunk("createPlan", async (params = {}, {getState, dispatch}) => {
  const state = getState()
  params.product = state.products.productSelected?.value
  const response = await api.post(`api/plans`, params)
  if (response.data.status === "success") {
    dispatch(showModalSuccess(params.from === 'providers' ? {message: response.data.message} : {message: response.data.message, url: '/catalogs/plans'}))
  }
  return {
    data: response.data
  }
})

export const updatePlan = createAsyncThunk("updatePlan", async ({planId, payload = {}}, {dispatch}) => {
  const response = await api.put(`api/plans/${planId}`, payload)
  if (response.data.status === 'success') {
    dispatch(showModalSuccess(payload.from === 'providers' ? {message: response.data.message} : {message: response.data.message, url: '/catalogs/plans'}))
  }
  return {
    data: response.data
  }
})

export const deletePlan = createAsyncThunk("deletePlan", async ({ planId, url = '/catalogs/plans'}, {dispatch}) => {
  const response = await api.delete(`api/plans/${planId}`)
  if (response.data.status === 'success') {
    dispatch(showModalSuccess({message: response.data.message, url}))
    dispatch(getPlans({page: 1}))
  }
  return {
    data: response.data
  }
})

const initialState = {
    data: [],
    suggestions: [],
    currentPage: 1,
    params: {page: 1},
    meta: {},
    total: 0,
    loading: false, // datatable loading
    loadingId: false, // retrieve by id loading
    sending: false, // loading when submit
    plan: []
  }

export const planSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    resetPlans: initialState
  },
  extraReducers: (builder) => {

    builder.addCase(getPlans.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createPlan.pending, (state) => {
      state.sending = true
    })
    builder.addCase(createPlan.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(createPlan.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(getPlans.rejected, (state) => {
      state.loading = false
      state.data = []
    })
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload?.params?.format === 'options') {
        state.data = action.payload.data
      } else {
        state.data = action.payload.data.data
        state.currentPage = action.payload.data.meta.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.meta.total
      }
    })
    builder.addCase(updatePlan.pending, (state) => {
      state.sending = true
    })
    builder.addCase(updatePlan.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(updatePlan.fulfilled, (state) => {
      state.sending = false
      state.plan = {}
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getPlansById.fulfilled, (state, action) => {
      state.loadingId = false
      state.plan = action.payload.data.data
    })
    builder.addCase(getPlansById.pending, (state) => {
      state.loadingId = true
    })
    builder.addCase(getPlansById.rejected, (state) => {
      state.loadingId = false
    })
  }
})

export const {resetPlans} = planSlice.actions

export default planSlice.reducer
