// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getCountries = createAsyncThunk("Address/getCountry", async () => {
  const response = await api.get(`api/countries?filter=all`)
  return {
    data: response.data
  }
})

export const getStates = createAsyncThunk(
  "Address/getState",
  async (country) => {
    if (country) {
      const response = await api.get(`api/states/${country}`)
      return {
        data: response.data
      }
    } else {
      return {
        data: []
      }
    }
  }
)
export const getCities = createAsyncThunk(
  "Address/getCities",
  async (state) => {
    if (state) {
      const response = await api.get(`api/cities/${state}`)
      return {
        data: response.data
      }
    } else {
      return {
        data: []
      }
    }
  }
)

export const getTypesOfIndustry = createAsyncThunk(
  "typesOfIndustry/get",
  async () => {
    const response = await api.get(`api/types_of_industry`)
    return {
      data:response.data
    }
  }
)

export const setTypesOfIndustry = createAsyncThunk(
  "typesOfIndustry/set",
  async (data) => {
    const response = await api.post(`api/types_of_industry`, data)
    return {
      data: response.data
    }
  }
)

export const SliceAddress = createSlice({
  name: "address",
  initialState: {
    countries: [
      {
        value: 1,
        label: "México"
      }
    ],
    states: [],
    cities: [],
    industries: [],
    newIndustrie:null
  },
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data
    })
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.states = action.payload.data
    })
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload.data
    })
    builder.addCase(getTypesOfIndustry.fulfilled, (state, action) => {
      state.industries = action.payload.data
    })
    builder.addCase(setTypesOfIndustry.fulfilled, (state, action) => {
      state.newIndustrie = {
        id: action.payload.data.id,
        name: action.payload.data.name
      }
    })
  }
})

export default SliceAddress.reducer
