import React from 'react'
import Lottie from 'react-lottie'
import animation from '@src/assets/lotties/loading_shield'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation
}

export const LoadingShield = () => {
    return (
        <Lottie options={defaultOptions} height={400} width="auto"/>
    )
}