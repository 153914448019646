// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalTypeDelete = createSlice({
  name: 'modals',
  initialState: {
    toggle: false,
    message: "",
    action: null,
    oneButton: null,
    acceptButtonText: "Si, Eliminar",
    cancelButtonText: "No, regresar",
    autoClose: true,
    loading: false,
    redirect: null,
    notes: null
  },
  reducers: {
    showModalDelete: (state, action) => {
      state.toggle = true
      state.message = action.payload.message
      if (action?.payload?.action) {
        state.action = action.payload.action
      }
      if (action?.payload?.oneButton) {
        state.oneButton = action.payload.oneButton
      }
      if (action?.payload?.acceptButtonText) {
        state.acceptButtonText = action.payload.acceptButtonText
      }
      if (action?.payload?.cancelButtonText) {
        state.cancelButtonText = action.payload.cancelButtonText
      }
      if (action?.payload?.autoClose !== undefined) {
        state.autoClose = action.payload.autoClose
      }
      if (action?.payload?.redirect) {
        state.redirect = action.payload.redirect
      }
      if (action?.payload?.notes) {
        state.notes = action.payload.notes
      }
    },
    hideModalDelete: (state) => {
      state.toggle = false
      state.message = ""
      state.action = null
      state.acceptButtonText = "Si, Eliminar"
      state.cancelButtonText = "No, regresar"
      state.autoClose = true
      state.loading = false
      state.redirect = null
      state.oneButton = null
      state.notes = null
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { showModalDelete, hideModalDelete, setLoading } = modalTypeDelete.actions

export default modalTypeDelete.reducer
