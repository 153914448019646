// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { Geocode } from "@src/configs/maps"

export const getCoordinates = createAsyncThunk(
  "maps/coords",
  async ({ address, type }, {rejectWithValue}) => {
    try {      
      // console.debug("sending to get coords: ", address)
      const response = await Geocode.fromAddress(address)
      // console.debug("response from geocode: ", response)
      const { lat, lng } = response.results[0].geometry.location
      return {
        address,
        type,
        data: { lat, lng }
      }
    } catch (error) {
      // console.error("error from getCoordinates", error)
      return rejectWithValue(error.response)
    }
  }
)

export const mapsSlice = createSlice({
  name: "maps",
  initialState: {
    origin: null,
    destination: null
  },
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getCoordinates.fulfilled, (state, action) => {
      if (action.payload.type === "origin") {
        state.origin = action.payload.data
      } 
      if (action.payload.type === 'destination') {
        state.destination = action.payload.data
      }
    })
  }
})

export default mapsSlice.reducer
