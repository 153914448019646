import Geocode from "react-geocode"

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
export const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY


Geocode.setApiKey(MAPS_API_KEY)

// set response language. Defaults to english.
Geocode.setLanguage("es")

export {Geocode}