// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getDestCountries = createAsyncThunk("Destination/getCountry", async () => {
  const response = await api.get(`api/countries?filter=all`)
  return {
    data: response.data
  }
})

export const getDestStates = createAsyncThunk(
  "Destination/getState",
  async (country) => {
    const response = await api.get(`api/states/${country}`)
    return {
      data: response.data
    }
  }
)
export const getDestCities = createAsyncThunk(
  "Destination/getCities",
  async (state) => {
    const response = await api.get(`api/cities/${state}`)
    return {
      data: response.data
    }
  }
)

export const SliceDestination = createSlice({
  name: "destination",
  initialState: {
    countries: [],
    states: [],
    cities: []
  },
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getDestCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data
    })
    builder.addCase(getDestStates.fulfilled, (state, action) => {
      state.states = action.payload.data
    })
    builder.addCase(getDestCities.fulfilled, (state, action) => {
      state.cities = action.payload.data
    })
  }
})

export default SliceDestination.reducer
