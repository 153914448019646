// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getClientsList = createAsyncThunk("clients/getList", async (params) => {
    const response = await api.get(`api/clients/all/list`, {params})
    return {
      params,
      data: response.data.map((client) => {
          return {
            value: client.role_id,
            label: client.label
          }
      })
    }
})

export const wizardSlice = createSlice({
    name: "clients",
    initialState: {
        data: [], // data from backend
        response: [],
        modifyCert: {
          netPremium: 0
        }
    },
    reducers: {
      //
    },
    extraReducers: (builder) => {
      builder.addCase(getClientsList.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
    }
})

export const { addWizardInfo } = wizardSlice.actions
  
export default wizardSlice.reducer