// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const responseSlice = createSlice({
  name: "response",
  initialState: {
    message: "",
    errors: [],
    status: "",
    loading: false,
    resource: ""
  },
  reducers: {
    setLoading(state, action) {
      state.loading = true
      state.resource = action?.payload?.resource
    },
    setNotLoading(state) {
      state.loading = false
    },
    setResponseMesage(state, action) {
      state.message = action.payload.message
      state.errors = action.payload.errors
      state.status = action.payload.status
    },
    cleanErrors(state) {
      state.message = ""
      state.errors = []
      state.status = ""
    }
  }
})

export const { setResponseMesage, cleanErrors, setLoading, setNotLoading } = responseSlice.actions

export default responseSlice.reducer
