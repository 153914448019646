// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalTypeSuccess = createSlice({
  name: 'success',
  initialState: {
    toggle: false,
    message: "",
    subtitle: null,
    url: null,
    action: null
  },
  reducers: {
    showModalSuccess: (state, action) => {
      state.toggle = true
      state.message = action.payload.message

      if (action.payload?.url) {
        state.url = action.payload.url
      } else {
        state.url = null
      }

      if (action.payload?.action) {
        state.action = action.payload.action
      }

      if (action.payload?.subtitle) {
        state.subtitle = action.payload.subtitle
      }
      
    },
    hideModalSuccess: (state) => {
      state.toggle = false
      state.message = ""
      state.action = null
      state.subtitle = null
    }
  }
})

export const { showModalSuccess, hideModalSuccess } = modalTypeSuccess.actions

export default modalTypeSuccess.reducer
