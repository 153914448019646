/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"
import { showModalFullCustom } from "@src/redux/modalTypeFullCustom"
import { Success, Danger } from "@src/assets/images/icons"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import { ToastSuccess } from "../../../assets/images/icons"
// ** Url base
const urlBase = "api/prospects"
/**
 * @description Función asíncrona para obtener los prospects
 * @param {Object} params
 * @returns {Promise}
 */
export const getProspects = createAsyncThunk("prospects/get", async (params, { getState }) => {
  try {
    const state = getState()
    params = { ...params, product_selected: state.products.productSelected.value, business_line_selected: state.businessLines.businessLineSelected.id }
    const response = await api.get(`${urlBase}`, { params })
    return response
  } catch (error) {
    console.error("❌ | file: index.js:getProspects | error:", error)
  }
})
/**
 * @description Función asíncrona para obtener las sugerencias del buscador de Prospectos
 * @param {Object} params
 * @returns {Promise}
 */
export const getSuggestions = createAsyncThunk("prospects/getSuggestions", async (params) => {
  try {
    const response = await api.get(`${urlBase}/suggestions`, { params })
    return response
  } catch (error) {
    console.error("❌ | file: index.js:getSuggestions | error:", error)
  }
})
/**
 * @description Función asíncrona para crear un nuevo prospecto
 * @param {Object} data
 */
export const setNewProspect = createAsyncThunk("prospects/set", async (data, { dispatch }) => {
  try {
    const response = await api.post(`${urlBase}`, data)
    if (response.data.status === 'success') {
      dispatch(showModalFullCustom({
        icon: <Success />,
        message: "El Prospecto ha sido creado exitosamente."
      }))
      return response.data.prospect.id
    }
  } catch (error) {
    console.error("❌ | file: index.js:setNewProspect | error:", error)
    let errorMessage = "Algo salió mal al generar el prospecto."
    if (error.response?.data?.message) {
      const serverMessage = error.response.data.message
      if (serverMessage.includes("Duplicate entry") || serverMessage.includes("prospects_email_unique") || serverMessage.includes("El email ya está siendo utilizado")) {
        errorMessage = "El correo electrónico ya está en uso."
      }
    }
    dispatch(showModalFullCustom({
      icon: <Danger />,
      message: errorMessage
    }))
    return false
  }
})
/**
 * @description Función asíncrona para eliminar un prospecto por id
 */
export const deleteProspect = createAsyncThunk("prospects/delete", async (id, { dispatch }) => {
  try {
    const response = await api.delete(`${urlBase}/${id}`)
    if (response.data.status === 'success') {
      dispatch(showModalFullCustom({
        icon: <Success />,
        message: "El Prospecto ha sido eliminado exitosamente."
      }))
    }
  } catch (error) {
    console.error("❌ | file: index.js:deleteProspect | error:", error)
    dispatch(showModalFullCustom({
      icon: <Danger />,
      message: "Algo salió mal al eliminar el prospecto."
    }))
  }
})
/**
 * @description Función asíncrona para obtener un prospecto por id
 * @param {String} id
 */
export const getProspectData = createAsyncThunk("prospects/getProspect", async (id) => {
  try {
    const response = await api.get(`${urlBase}/${id}`)
    return response.data
  } catch (error) {
    console.error("❌ | file: index.js:getProspectData | error:", error)
  }
})
/**
 * @description Función asíncrona para actualizar un prospecto por id
 */
export const updateProspect = createAsyncThunk("prospects/update", async (data, { dispatch }) => {
  try {
    const response = await api.put(`${urlBase}/${data.id}`, data)
    if (response.data.status === 'success') {
      dispatch(showModalFullCustom({
        icon: <Success />,
        message: "El Prospecto ha sido actualizado exitosamente."
      }))
      return true
    }
  } catch (error) {
    console.error("❌ | file: index.js:updateProspect | error:", error)
    dispatch(showModalFullCustom({
      icon: <Danger />,
      message: "Algo salió mal al actualizar el prospecto."
    }))
    return false
  }
})
/**
 * @description Función asíncrona para mandar corro de registro para que un prospecto se convierta en cliente llenando un formulario
 */
export const sendEmailToProspect = createAsyncThunk("prospects/sendEmail", async (data) => {
  try {
    const response = await api.post(`${urlBase}/sendEmail/${data.id}`, data)
    toast(<SuccessToast message={response.data.message} />, {
      icon: <ToastSuccess />,
      hideProgressBar: true,
      className: "toast-success",
      autoClose: 5000
    })
  } catch (error) {
    console.error("❌ | file: index.js:sendEmailToProspect | error:", error)
    toast(<ErrorToast message={"Error al enviar el correo"} />, {
      icon: null,
      hideProgressBar: true,
      className: "toast-danger",
      autoClose: 5000
    })
  }
})
/**
 * @description Función asíncrona para convertir un prospecto en cliente
 */
export const convertToClient = createAsyncThunk("prospects/convertToClient", async (id) => {
  try {
    const response = await api.post(`${urlBase}/convertToClient/${id}`)
    return response
  } catch (error) {
    console.error("❌ | file: index.js:convertToClient | error:", error)
  }
})
// ** Initial slice values
const prospectsSlice = createSlice({
  name: "prospects",
  initialState: {},
  reducers: {
    resetProspect: (state) => {
      state.prospect = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProspects.fulfilled, (state, action) => {
      state.prospects = action.payload.data
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getProspectData.fulfilled, (state, action) => {
      state.prospect = action.payload.data
    })
  }
})
export const { resetProspect } = prospectsSlice.actions
export default prospectsSlice.reducer