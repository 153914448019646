/* eslint-disable no-unused-vars */

export interface City {
  id: number | null;
  label: string | null;
}

export interface State {
  id: number | null;
  label: string | null;
}

export interface Country {
  id: number | null;
  label: string | null;
}

export interface TaxSystem {
  value: number | null;
  label: string | null;
}

export interface UsoCfdi {
  label: string | null;
  value: number | null;
}

export interface Currency {
  label: string | null;
  value: number | null;
}

export interface FullCurrency {
  id: number;
  cfdi_value: string;
  description: string;
}

export interface Pivot {
  user_id: number;
  product_id: number;
  base_fee: number;
  sales_fee: number;
  plan_id: number | null;
  project_profile_id: number | null;
  min_cost: number | null;
  max_cost: number | null;
  business_line_id: number;
}

export interface Product {
  id: number;
  name: string;
  short_name: string;
  theme: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  folio_prefix: string;
  key: string;
  business_line_id: number;
  stripe_secret_webhook: string | null;
  pivot: Pivot;
}

export interface BusinessLineFullInfo {
  id: number;
  short_name: string;
  business_name: string;
  rfc: string;
  full_address: string;
  postal_code: string;
  graph_color: string;
  key: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  laravel_through_key: number;
}

interface Seller {
  id: number;
  status: boolean;
  role_id: number | null;
  full_name: string;
  name: string;
  last_name: string;
  last_name_2: string;
  suggestion: string;
  email: string;
  phone: string;
  role: string;
  rfc: string | null;
  tax_system_name: string | null;
  birthdate: string | null;
  clabe: string | null;
  city: City;
  state: State;
  country: Country;
  tax_system: TaxSystem;
  created_at: string | null;
  products: Product[];
  businesslines: BusinessLineFullInfo[];
}

export interface Preferences {
  business_line_id: number;
  client_id: number; // ATTENTION: This is the user id, not the client id
  currency_id: number;
  currency: FullCurrency;
  product_id: number;
  provider_pri_id: number | null;
  provider_sec_id: number | null;
}

export interface GetClientsResponse {
  data: any;
  params: any;
}

export interface GetClientByIdResponse {
  id: number;
  status: boolean;
  role_id: number;
  full_name: string;
  name: string;
  user_full_name: string;
  payment_form_mxn: number;
  contact_name: string;
  last_name: string;
  last_name_2: string;
  suggestion: string;
  seller: Seller;
  taxData: any[]; // Ajusta el tipo según la estructura de `taxData`
  managers: any[]; // Ajusta el tipo según la estructura de `managers`
  type_of_industry: number;
  type_of_industry_option: {
    value: number;
    label: string;
  };
  billing_type: string;
  billing_period: string | null;
  billing_label: string;
  billing_day: number | null;
  email: string;
  phone: string;
  role: string;
  rfc: string | null;
  seller_name: string;
  created_at: string;
  url_activation: string;
  products: Product[];
  client_type_id: number | null;
  client_type: any; // Ajusta el tipo según la estructura de `client_type`
  preferences: Preferences[];
  contact_position: string | null;
  frequency_of_issuance: string | null;
  key_features: any[] | null;
}

export enum ResponseStatusEnum {
  success = "success",
  error = "error",
  loading = "loading",
  idle = "idle"
}

export interface CreateResponse {
  status: ResponseStatusEnum;
  message: string | null;
  data: ClientDataResponse | null;
}

export interface ProductData {
  id: number | undefined;
  cuota_base?: number | null;
  cuota_venta?: number | null;
  client_type?: number | null;
  cuotas?: {
    cuota_base: number;
    cuota_venta: number;
  }[];
  plan_id?: number;
  costo_minimo?: number;
  costo_maximo?: number;
}

interface BusinessLine {
  id: number | undefined;
  products: ProductData[];
}

export interface CreateClientParams {
  correo_electronico: string;
  nombres: string;
  forma_pago: boolean;
  telefono: string;
  contacto_nombres: string;
  apellido_paterno: string;
  apellido_materno: string;
  tipo_de_industria: number | null;
  provider_primary: number | null;
  provider_secondary: number | null;
  tipo_facturacion: string;
  periodo_corte: string | null;
  dia_corte: number | null;
  vendedor: number | null;
  currencySelected: number | null;
  business_lines: BusinessLine[];
  tipos_cliente: number | null;
  contact_position: string | null,
  frequency_of_issuance: string | null;
  key_features:  any[]  | null;
}

export interface BaseResponse<T> {
  status: string;
  data: T;
  message: string;
}

export interface SellerResource {
  id: number;
  status: boolean;
  role_id: number | null;
  full_name: string;
  name: string;
  last_name: string;
  last_name_2: string;
  suggestion: string;
  email: string;
  phone: string;
  role: string;
  rfc: string | null;
  tax_system_name: string | null;
  birthdate: string | null;
  clabe: string | null;
  city: City | null;
  state: State | null;
  country: Country | null;
  tax_system: TaxSystem;
  created_at: string | null;
  products: any;
  businesslines: any;
}

export interface TaxDataNestedResource {
  id: number;
  origin: string;
  suggestion: string;
  facturapi_id: string;
  business_type: string | null;
  full_address: string;
  rfc: string;
  type: string;
  business_name: string;
  names: string;
  last_name: string;
  last_name_2: string;
  tax_id: string;
  postal_code: string;
  suburb: string;
  address: string;
  location: string;
  taxid: string;
  product_id: number;
  tax_system: TaxSystem | null;
  uso_cfdi: UsoCfdi | null;
  currency_id: Currency | null;
  client_id: number;
  city: City | null;
  state: State | null;
  country: Country | null;
  created_at: string | null;
}

export interface ManagersToNotifyResource {
  id: number;
  email: string;
  document_type: string;
  fullname: string;
  names: string;
  last_name: string;
  last_name_2: string;
  client_id: number;
  created_at: string;
}

export interface ClientType {
  id: number;
  name: string;
}

export interface ClientDataResponse {
  id: number;
  status: boolean;
  role_id: number | null;
  full_name: string;
  name: string; // client name
  user_full_name: string;
  payment_form_mxn: string | null;
  contact_name: string;
  last_name: string;
  last_name_2: string;
  suggestion: string;
  seller: SellerResource;
  taxData: TaxDataNestedResource[];
  managers: ManagersToNotifyResource[];
  type_of_industry: number | null;
  type_of_industry_option: {
    value: number | null;
    label: string | null;
  } | null;
  billing_type: string | null;
  billing_period: string | null;
  billing_label: string | null;
  billing_day: number | null;
  email: string;
  phone: string;
  role: string;
  rfc: string | null;
  seller_name: string | null;
  created_at: string | null;
  url_activation: string | null;
  products: any; // Ajusta el tipo según la estructura de `products`
  client_type_id: number | null;
  client_type: ClientType | null; // Ajusta el tipo según la estructura de `clientType`
}

export type CreateClientResponse = BaseResponse<ClientDataResponse>;

export interface FeeType {
  id: number;
  name: string;
  project_profile_id: number;
}

export interface ProjectProfileType {
  id: number;
  client_types_id: number;
  project_profile_id: number;
  base_fee: number;
  sales_fee: number;
}

export interface Provider {
  id: number;
  prefix: string;
  name: string;
  business_name: string;
}

export interface ProjectProfile {
  id: number;
  name: string;
  currency: FullCurrency;
  max_amount: number;
  min_amount: number;
  fee_types: FeeType[];
  project_profile_types: ProjectProfileType[];
  provider: Provider | null;
}

export interface ProjectProfileBody {
  business_line: number;
  product: number;
  client_type: number;
}

export interface ProjectProfilesResponse {
  data: ProjectProfile[] | null;
  status: ResponseStatusEnum;
}

export interface UpdateClientResponse {
  message: string | null;
  status: ResponseStatusEnum;
}

export interface UpdateClientParams {
  id: string;
  data: CreateClientParams;
  url?: string | null;
  action?: any;
}

export interface UpdateScheduledInvoiceByClientResponse {
  status: ResponseStatusEnum;
  data: any;
}

export interface UpdateScheduledInvoiceByClientParams {
  clientId: string;
  data: any;
  toggleHandler: () => void;
}

export interface SellerClientsState {
  data: any;
  client: GetClientByIdResponse | null;
  seller: any;
  taxData: any;
  managers: any[];
  total: number;
  defaultValues: any;
  saved: boolean;
  create: CreateResponse;
  profiles: ProjectProfilesResponse;
  update: UpdateClientResponse;
  sheduledInvoices: UpdateScheduledInvoiceByClientResponse;
}
