// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { useHistory } from "react-router-dom"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

export const getAccountant = createAsyncThunk(
  "accountants/getById",
  async (id) => {
    const response = await api.get(`api/accountants/${id}`)
    return {
      data: response.data
    }
  }
)

export const deleteAccountant = createAsyncThunk(
  "accountants/delete",
  async (id, {dispatch}) => {
    const response = await api.delete(`api/accountants/${id}`)
    if (response.data.status  === 'success') {
        dispatch(showModalSuccess({message: response.data.message}))
        dispatch(getAccountant(id))
        useHistory.push('/users/accountants')
    }
    return {
      data: response.data
    }
  }
)

export const activeAccountant = createAsyncThunk(
  "accountants/active",
  async (id, {dispatch}) => {
    const response = await api.patch(`api/active/accountants/${id}`)
    if (response.data.status  === 'success') {
      dispatch(showModalSuccess({message: response.data.message}))
      dispatch(getAccountant(response.data.data.role_id))
    }
    return {
      data: response.data
    }
  }
)

export const AccountantsSlice = createSlice({
  name: "accountants",
  initialState: {
    data: null,
    total: 0,
    defaultValues: null,
    accountant: null
  },
  reducers: {
      //
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAccountant.fulfilled, (state, action) => {
        // console.debug("deleteAccountant", action.payload)
        state.data = action.payload.data
    })
    builder.addCase(activeAccountant.fulfilled, (state, action) => {
        // console.debug("activeAccountant", action.payload)
        state.data = action.payload.data
    })
    builder.addCase(getAccountant.fulfilled, (state, action) => {
      // console.debug("activeAccountant", action.payload)
      state.accountant = action.payload.data.data[0]
    })
  }
})

export default AccountantsSlice.reducer
