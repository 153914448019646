// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Fragment } from "react"
// ** Axios Imports
import api from "@src/api/config"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"
import { showModalSuccess } from "../../../redux/modalTypeSuccess"
import { showModalError } from "../../../redux/modalTypeError"

/**
 * Only accountants are allowed to cancel invoices
 */
 export const cancelInvoice = createAsyncThunk("invoices/cancel", async ({ id, params }, { dispatch }) => {
  try {
    const response = await api.delete(`api/invoices/${id}`, { params })

    if (response.data.status === 'success') {
      dispatch(showModalSuccess({
        message: 'Solicitud aprobada con éxito',
        subtitle: 'El documento podrá ser descargado, pero aparecerá con la leyenda de “cancelada” para evitar equivocaciones.',
        url: `/invoices/show/${id}`
      }))
    }

    return {
      data: response.data
    }
  } catch (err) {
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const getCertificatesFromInvoice = createAsyncThunk(
  "requestCancellation/getCertificatesFromInvoice",
  async (invoice) => {
    const response = await api.get(`api/invoice/certificates/${invoice}`)
    return {
      data: response.data
    }
  }
)

export const getRequestCancellation = createAsyncThunk(
  "requestCancellation/get",
  async (id) => {
    const response = await api.get(`api/request_cancel/${id}`)
    return {
      data: response
    }
  }
)

export const getRequestCancellationByInvoice = createAsyncThunk(
  "requestCancellation/getByInvoice",
  async (invoice, {dispatch}) => {
    try {
      const response = await api.get(`api/request_cancel/${invoice}/in_progress`)
      return {
        data: response.data
      }
    } catch (err) {
      dispatch(getCertificatesFromInvoice(invoice))
    }
    
  }
)

export const getReplacements = createAsyncThunk(
  "requestCancellation/getSubstitutes",
  async (invoice) => {
    const response = await api.get(`api/request_cancel/${invoice}/certificates`)
    return {
      data: response
    }
  }
)

/**
 * Sellers and admins can send a request to accountant for cancel invoices
 */
export const requestToCancelInvoice = createAsyncThunk("requestCancell/create", async ({
  id,
  params,
  showModal = true,
  url = null,
  subtitle = null,
  message = null,
  action = null,
  next = '',
  closeModal = '',
  history = null
}, { dispatch }) => {
  try {

    const response = await api.post(`api/request_cancel/${id}`, params)
    // console.debug('showModal', showModal)
    if (response.data.status === 'success' && showModal) {
      if (next) next()
      dispatch(showModalSuccess({
        message,
        subtitle,
        url
      }))
    } else if (response.data.status === 'success' && !showModal && action === 'redirect') {
      history.push(url)
    } else if (response.data.status === 'success' && !showModal && action === 'next' && next !== null) {
      next()
    } else {
      // console.log("Response:", response.data)
    }

    // localStorage.clear('indications')
    return {
      data: response.data,
      params
    }
  } catch (err) {
    if (closeModal) {
      closeModal()
    }

    dispatch(
      showModalError({
        message: err.response.data?.message ?? "No fue posible cancelar la factura"
      })
    )
    
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const updateCancelInvoice = createAsyncThunk("requestCancell/update", async ({ id, params, showModal = true, url = null, subtitle = null, message = null, action = null, next = '', history = null}, { dispatch }) => {
  try {
    // console.log('UpdateCancelInvoice')
    const response = await api.put(`api/request_cancel/${id}`, params)
    // console.debug('showModal', showModal)
    if (response.data.status === 'success' && showModal) {
      dispatch(showModalSuccess({
        message,
        subtitle,
        url
      }))
    } else if (response.data.status === 'success' && !showModal && action === 'redirect') {
      history.push(url)
    } else if (response.data.status === 'success' && !showModal && action === 'next' && next !== null) {
      next()
    } 

    // localStorage.clear('indications')
    return {
      data: response.data,
      params
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const patchTaxData = createAsyncThunk(
  "requestCancellation/updateTaxData",
  async ({payload, success = null, error = null, showModal = true}, {dispatch}) => {
    try {
      const response = await api.patch(`api/taxdata`, payload)

      if (response.data.status === 'success') {
        if (showModal) {
          // update request cancellation with the original data on 
          dispatch(showModalSuccess({
            message: response.data.message
          }))
        }
        if (success !== null) success()
      }
      return {
        data: response
      }
    } catch (err) {
      if (error !== null) error()
      // console.log('Error:', err.response.data?.message)
      toast(<ErrorToast message={err.response.data?.message ?? "No fue posible actualizar los datos fiscales"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
      throw new Error(err.response.data?.message ?? "No fue posible actualizar los datos fiscales")
    }
  }
)

export const rejectCancellation = createAsyncThunk("invoices/reject_cancellation", async ({ id, params, message = null, subtitle = null }, {dispatch}) => {
  try {
    const response = await api.delete(`api/request_cancel/${id}`, { params })
   
    if (response.data.status === 'success') {
      const payload = {
        message: message !== null ? message : 'Solicitud rechazada con éxito',
        subtitle: subtitle !== null ? subtitle : 'La factura será devuelta a su estado original antes de la solicitud.',
        url: '/invoices'
      }
      dispatch(showModalSuccess(payload))
    }
    
    return {
      data: response.data
    }
  } catch (err) {
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const approveCancellation = createAsyncThunk("invoices/approval_cancellation", async ({id, params, success = null, error = null}, {dispatch}) => {
  // console.log('Params:', params)
  try {
    const response = await api.post(`api/request_cancel/${id}/approve`, { ...params })

    if (response.data.status === 'success') {
      if (success) success()
      const invoice = response.data.data.id
      // console.debug("Redirect to invoice: ", invoice)
        // redirect to new invoice
        dispatch(showModalSuccess({
          message: 'Solicitud aprobada con éxito',
          subtitle: 'El documento podrá ser descargado, pero aparecerá con la leyenda de “cancelada” para evitar equivocaciones.',
          url: `/invoices/show/${invoice}`
        }))
    }
    return {
      data: response.data
    }
  } catch (err) {
    if (error) error()
    dispatch(showModalError({ message: err.response.data?.message ?? "No fue posible aprobar la cancelación de la factura" }))
    throw new Error(err.response.data?.message ?? "No fue posible aprobar la cancelación de la factura")
  }
})

const getInvoiceSelected = () => {
  const invoice = localStorage.getItem("invoiceToCancel")
  return invoice ? JSON.parse(invoice) : null
}

export const cancellationSlice = createSlice({
  name: "cancellations",
  initialState: {
    certificates_preloadinfo: [],
    invoice_to_cancel: getInvoiceSelected(),
    request_cancellation: null,
    certificates_modified: null,
    certificates_original: null,
    loading: false,
    replacements: {},
    replacements_loading: true,
    tax_data_status: 'idle',
    // requestToCancelInvoice
    status: 'idle',
    // rejectCancellation
    reject_status: 'idle',
    // approveCancellation
    approve_status: 'idle',
    // cancelInvoice
    cancel_status: 'idle',
    // getRequestCancellationByInvoice
    certificates_status: 'idle',
    disable_next_btn: false
  },
  reducers: {
    resetStates: (state) => {
      state.tax_data_status = 'idle'
      state.certificates_preloadinfo = []
      state.invoice_to_cancel = getInvoiceSelected()
      state.request_cancellation = null
      state.certificates_modified = null
      state.loading = false
      state.replacements = {}
      state.replacements_loading = true
      state.status = 'idle'
    },
    disableNextButton: (state) => {
      state.disable_next_btn = true
    },
    resetLoading: (state) => {
      state.status = 'idle'
    },
    selectInvoiceToCancel: (state, action) => {
      const invoice = action.payload?.invoice
      if (invoice) {
        // set invoice
        localStorage.setItem("invoiceToCancel", JSON.stringify(invoice))
        state.invoice_to_cancel = action.payload.invoice
      }
    },
    setCertificatesModified: (state, action) => {
      state.certificates_modified = action.payload.certificates
    },
    cleanInvoiceSelected: (state) => {
      // state.invoice_to_cancel = null
      state.request_cancellation = null
      state.loading = false
      state.status = 'idle'
      state.tax_data_status = 'idle'
      state.reject_status = 'idle'
      state.approve_status = 'idle'
      state.cancel_status = 'idle'
      state.certificates_status = 'idle'
      state.disable_next_btn = true
    },
    removeItem: (state, action) => {
      const tmp = state.certificates_modified
      if (tmp !== null) {
        const index = tmp.findIndex((element) => element.id === action.payload.element.id)
        if (index >= 0) {
          tmp.splice(index, 1)
        }
      }
    },
    restoreItem: (state, action) => {
      const tmp = state.certificates_modified
      tmp.push(action.payload.element)
      state.certificates_modified = tmp
    },
    updateCertificateAmount: (state, action) => {
      state.certificates_modified = state.certificates_modified.map((cert) => {
        if (cert.id === action.payload.id) {
          return {
            ...cert,
            mark_amount: parseFloat(cert.amount) !== parseFloat(action.payload.ensured_amount), 
            amount: action.payload.ensured_amount,
            mark_shipping_cost: parseFloat(cert.shipping_cost) !== parseFloat(action.payload.shipping_cost), 
            shipping_cost: action.payload.shipping_cost,
            net_premium: action.payload.ensured_amount * cert.sales_fee / 100,
            subtotal: (action.payload.ensured_amount * cert.sales_fee / 100) + parseFloat(action.payload.shipping_cost),
            iva: ((action.payload.ensured_amount * cert.sales_fee / 100) + parseFloat(action.payload.shipping_cost)) * 0.16,
            total: ((action.payload.ensured_amount * cert.sales_fee / 100) + parseFloat(action.payload.shipping_cost)) * 1.16
          }
        } else {
          return cert
        }
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRequestCancellation.fulfilled, (state, action) => {
      state.request_cancellation = action.payload.data.data
    })
    builder.addCase(getCertificatesFromInvoice.fulfilled, (state, action) => {
      state.certificates_modified = action.payload.data.data
    })
    // redirect to error page if request cancellation doesn't exists
    builder.addCase(getRequestCancellation.rejected, () => {
      window.location.href = "/error"
    })
    builder.addCase(getReplacements.fulfilled, (state, action) => {
      state.replacements_loading = false
      // state.replacements = action.payload.data.data // pagination
      state.replacements = action.payload.data.data.data
    })
    builder.addCase(getReplacements.rejected, (state) => {
      state.replacements_loading = false
    })
    builder.addCase(getRequestCancellationByInvoice.pending, (state) => {
      state.certificates_status = 'loading'
    })
    builder.addCase(getRequestCancellationByInvoice.fulfilled, (state, action) => {
      // console.debug("getRequestCancellationByInvoice", action.payload)
      if (action.payload?.data?.data) {
        state.certificates_preloadinfo = action.payload.data.data
      }
      const certs = action.payload?.data?.data?.certificate_changes
      if (certs) {
        state.request_cancellation = action.payload.data.data
        state.certificates_modified = certs
      }
      const cert_original = action.payload?.data?.data?.certificate_original
      if (cert_original) {
        state.certificates_original = cert_original
      }
      state.certificates_status = 'succeeded'
    })
    builder.addCase(getRequestCancellationByInvoice.rejected, () => {
      state.certificates_status = 'failed'
    })
    builder.addCase(requestToCancelInvoice.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(requestToCancelInvoice.fulfilled, (state, action) => {
      state.status = 'succeeded'
      if (action.payload.params?.button === 'save_changes') {
        state.disable_next_btn = false
      }
      state.request_cancellation = action.payload.data.data
    })
    builder.addCase(requestToCancelInvoice.rejected, (state) => {
      state.status = 'failed'
    })
    builder.addCase(updateCancelInvoice.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateCancelInvoice.fulfilled, (state, action) => {
      state.status = 'succeeded'
      if (action.payload.params?.button === 'save_changes') {
        state.disable_next_btn = false
      }
      state.request_cancellation = action.payload.data.data
    })
    builder.addCase(updateCancelInvoice.rejected, (state) => {
      state.status = 'failed'
    })
    builder.addCase(rejectCancellation.pending, (state) => {
      state.reject_status = 'loading'
    })
    builder.addCase(rejectCancellation.fulfilled, (state) => {
      state.reject_status = 'succeeded'
    })
    builder.addCase(rejectCancellation.rejected, (state) => {
      state.reject_status = 'failed'
    })
    builder.addCase(approveCancellation.pending, (state) => {
      state.approve_status = 'loading'
    })
    builder.addCase(approveCancellation.fulfilled, (state) => {
      state.approve_status = 'succeeded'
    })
    builder.addCase(approveCancellation.rejected, (state) => {
      state.approve_status = 'failed'
    })
    // Approve cancellation
    builder.addCase(cancelInvoice.pending, (state) => {
      state.cancel_status = 'loading'
    })
    builder.addCase(cancelInvoice.fulfilled, (state) => {
      state.cancel_status = 'succeeded'
    })
    builder.addCase(cancelInvoice.rejected, (state) => {
      state.cancel_status = 'failed'
    })
    // Patch tax data
    builder.addCase(patchTaxData.pending, (state) => {
      state.tax_data_status = 'loading'
    })
    builder.addCase(patchTaxData.fulfilled, (state) => {
      state.tax_data_status = 'succeeded'
    })
    builder.addCase(patchTaxData.rejected, (state) => {
      state.tax_data_status = 'failed'
    })
  }
})

export const {
  updateCertificateAmount,
  cleanInvoiceSelected,
  selectInvoiceToCancel,
  setCertificatesModified,
  removeItem,
  restoreItem,
  resetLoading,
  resetStates,
  disableNextButton
} = cancellationSlice.actions

export default cancellationSlice.reducer
