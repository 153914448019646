// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalTypeFullCustom = createSlice({
  name: 'modals_full_custom',
  initialState: {
    toggle: false, // flag to show/hide
    message: "", // main message
    icon: null, // main icon
    notes: null, // bottom text
    primaryButton: null,
    secondaryButton: null,
    url: null
  },
  reducers: {
    showModalFullCustom: (state, action) => {
      state.toggle = true
      state.message = action.payload.message
      state.icon = action.payload.icon
      state.primaryButton = action.payload.primaryButton
      state.secondaryButton = action.payload.secondaryButton
      state.url = action.payload.url ? action.payload.url : null
      if (action.payload?.notes) {
        state.notes = action.payload.notes
      }
    },
    hideModalFullCustom: (state) => {
      state.toggle = false
      state.message = ""
      state.icon = null
      state.primaryButton = null
      state.secondaryButton = null
      state.notes = null
      // state.url = null
    },
    clearUrl: (state) => {
      state.url = null
    }
  }
})

export const { showModalFullCustom, hideModalFullCustom, clearUrl } = modalTypeFullCustom.actions

export default modalTypeFullCustom.reducer
