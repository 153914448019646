import { Fragment } from "react"

const SuccessToast = ({ message, submessage }) => (
  <Fragment>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text-success' style={(submessage !== "" && submessage !== null) ? { fontWeight: 600, fontSize: 12 } : {}}>
        {message}
      </span><br /><br />
      {(submessage !== "" && submessage !== null) &&
        <span role='img' aria-label='toast-text-success' style={{ fontSize: 10 }}>
          {submessage}
        </span>
      }
    </div>
  </Fragment>
)

export default SuccessToast