// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalEditCertUpload = createSlice({
    name: 'modalEditCertUpload',
    initialState: {
        toggle: false,
        cert: null
    },
    reducers: {
        showEditCertUploadModal: (state, action) => {
            state.toggle = true
            state.cert = action.payload
        },
        hideEditCertUploadModal: (state) => {
            state.toggle = false
            state.cert = null
        }
    }
})

export const { showEditCertUploadModal, hideEditCertUploadModal } = modalEditCertUpload.actions

export default modalEditCertUpload.reducer
