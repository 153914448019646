// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
import { hideModalCustom } from "../../../../redux/modalTypeCustom"

// ** Axios Imports
import api from "@src/api/config"

const urlBase = "api/sellers"

export const getProducts = createAsyncThunk("layout/getProducts", async () => {
  const response = await api.get(`/api/products`)
  return {
    data: response.data
  }
})

export const getSellerClients = createAsyncThunk("sellers/getClients", async ({ id, params }) => {
  const response = await api.get(`api/seller-clients/${id}`, { params })
  return {
    data: response.data
  }
})

export const getSellerClientsSuggestions = createAsyncThunk("sellers/getClientsSuggestions", async (id) => {
  const response = await api.get(`api/seller-clients/${id}/suggestions`)
  return {
    data: response.data
  }
})

export const getSellerById = createAsyncThunk("sellers/getById", async ({ id }, { dispatch }) => {
  const response = await api.get(`${urlBase}/${id}`)
  dispatch(getSellerClients({ id: response.data.data[0].id }))
  dispatch(getSellerClientsSuggestions(response.data.data[0].id))
  // retrieve clients
  return {
    data: response.data
  }
})

export const getSellerName = createAsyncThunk("sellers/getName", async ({ id }) => {
  const response = await api.get(`${urlBase}/${id}/details`)
  return {
    data: response.data
  }
})

export const enableSeller = createAsyncThunk("sellers/enable", async ({ id }, { dispatch }) => {
  const response = await api.patch(`api/active/sellers/${id}`)
  if (response.data.status === "success") {
    dispatch(showModalSuccess({ message: response.data.message }))
    dispatch(getSellerById({ id: response.data.data.role_id }))
    dispatch(hideModalCustom())
  }
  return {
    data: response.data
  }
})

export const disableSeller = createAsyncThunk("sellers/disable", async ({ id }, { dispatch }) => {
  const response = await api.delete(`api/sellers/${id}`)
  if (response.data.status === "success") {
    dispatch(showModalSuccess({ message: response.data.message }))
    dispatch(hideModalCustom())
  }
  dispatch(getSellerById({ id }))
  return {
    data: response.data
  }
})

export const sellersSlice = createSlice({
  name: "sellers",
  initialState: {
    seller: [],
    seller_clients: [],
    seller_clients_suggestions: [],
    seller_name: "",
    params: {},
    clients_meta: {},
    total: 0,
    message: "",
    status: {},
    products: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSellerById.fulfilled, (state, action) => {
      // console.debug("getSellerById", action.payload.data)
      state.seller = action.payload.data.data[0]

    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      // console.debug("getProducts", action.payload.data)
      state.products = action.payload.data
    })
    builder.addCase(getSellerClients.fulfilled, (state, action) => {
      // console.debug("getSellerClients", action.payload.data)
      state.seller_clients = action.payload.data.data
      state.clients_meta = action.payload.data.meta
    })
    builder.addCase(getSellerClientsSuggestions.fulfilled, (state, action) => {
      // console.debug("getSellerClientsSuggestions", action.payload.data)
      state.seller_clients_suggestions = action.payload.data
    })
    builder.addCase(getSellerName.fulfilled, (state, action) => {
      state.seller_name = action.payload.data.full_name
    })
  }
})

export default sellersSlice.reducer
