/**

 * @description Redux slice for modalLinkProspectAtClient
 */
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalLinkProspectAtClient = createSlice({
  name: 'modalLinkProspectAtClient',
  initialState: {
    toggle: false,
    prospect: null
  },
  reducers: {
    showLinkProspectAtClient: (state, action) => {
      state.toggle = true
      state.prospect = action.payload.prospect
    },
    hideLinkProspectAtClient: (state) => {
      state.toggle = false
    }
  }
})

export const { showLinkProspectAtClient, hideLinkProspectAtClient } = modalLinkProspectAtClient.actions

export default modalLinkProspectAtClient.reducer