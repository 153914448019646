// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalClientActivation = createSlice({
    name: 'modalClientActivation',
    initialState: {
        toggle: false,
        client: null
    },
    reducers: {
        showClientActivationModal: (state, action) => {
            state.toggle = true
            state.client = action.payload.client
        },
        hideClientActivationModal: (state) => {
            state.toggle = false
        }
    }
})

export const { showClientActivationModal, hideClientActivationModal } = modalClientActivation.actions

export default modalClientActivation.reducer
