// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"
import { ToastSuccess } from "../../../assets/images/icons"
import { showModalError } from "../../../redux/modalTypeError"

export const getQuotations = createAsyncThunk(
  "quotations/get",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(`/api/quotations?created=internal`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getExternalQuotations = createAsyncThunk(
  "quotations/getExternalQuotations",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(`/api/quotations?created=external`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getMyQuotations = createAsyncThunk(
  "quotations/getMyQuotations",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(`/api/quotations`, { params })
    return {
      data: response.data
    }
  }
)

export const getQuotationDetails = createAsyncThunk(
  "quotations/details",
  async (id) => {
    const response = await api.get(`api/quotations/${id}/details`)
    return {
      data: response.data
    }
  }
)

export const getQuotationsOptions = createAsyncThunk(
  "quotations/getQuotationsOptions",
  async (quotationId) => {
    const response = await api.get(`/api/quotations/${quotationId}/options`)
    return {
      data: response.data
    }
  }
)

export const saveQuotation = createAsyncThunk(
  "quotations/post",
  async (params, { dispatch, getState }) => {
    console.debug("saveQuotation", params)
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    try {
      const response = await api.post(`/api/quotations`, { ...params })
      dispatch(getQuotationsOptions(response.data.id))
      return {
        data: response.data,
        params
      }
    } catch (error) {
      console.error("message error", error.response.data)
      const message = error.response.data.message
      // redirection
      const state = getState()
      const { userData } = state.auth
      let url = ""
      if (userData.role === "admin") {
        url = "/admin/quotations"
      } else if (userData.role === "seller") {
        url = "/seller/quotations"
      }
      dispatch(
        showModalError({
          message,
          url
        })
      )
    }
  }
)

export const getSuggestions = createAsyncThunk(
  "commissions/suggestions",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(`api/quotations/suggestions`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getExternalSuggestions = createAsyncThunk(
  "commissions/externalSuggestions",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(
      `api/quotations/suggestions?created=external`,
      {
        params
      }
    )
    return {
      data: response.data,
      params
    }
  }
)

export const getMySuggestions = createAsyncThunk(
  "commissions/mySuggestions",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.get(`api/quotations/suggestions`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const sendQuotationByEmail = createAsyncThunk(
  "commissions/sendQuotationByEmail",
  async ({ quotation_id, params }) => {
    try {
      const response = await api.post(`api/quotations/${quotation_id}/email`, {
        ...params
      })
      toast(<SuccessToast message={response.data.message} />, {
        icon: <ToastSuccess />,
        hideProgressBar: true,
        className: "toast-success",
        autoClose: 5000
      })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, {
        icon: null,
        hideProgressBar: true,
        className: "toast-danger",
        autoClose: 5000
      })
      throw err
    }
  }
)

// TODO move to different store, maybe this should not be in quotations section
export const getClientFee = createAsyncThunk(
  "clients/fee",
  async ({ client, params }) => {
    try {
      const response = await api.get(`api/clients/fees/${client}`, {
        params
      })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      console.error(err)
    }
  }
)

const clearResponse = createAsyncThunk("commissions/clearResponse", () => null)

/**
 * @typedef {Object} QuotationState
 * @property {any} data
 * @property {any} dataExternal
 * @property {any} myQuotations
 * @property {any} details
 * @property {string} suggestions.keyFilter
 * @property {string[]} suggestions.folio
 * @property {string[]} suggestions.seller
 * @property {string[]} suggestions.client
 * @property {any} tempQuotation
 * @property {string} externalSuggestions.keyFilter
 * @property {string[]} externalSuggestions.folio
 * @property {string[]} externalSuggestions.seller
 * @property {string[]} externalSuggestions.client
 * @property {string} mySuggestions.keyFilter
 * @property {string[]} mySuggestions.folio
 * @property {string[]} mySuggestions.seller
 * @property {string[]} mySuggestions.client
 * @property {any[]} quotationsOptions
 * @property {any} response
 * @property {any} summaryData
 * @property {any} client
 * @property {any} summaryData
 * @property {any} client
 * @property {any} salesFee
 * @property {string} name
 * @property {string} email
 * @property {any} clientFees
 * @property {'idle' | 'loading' | 'success' | 'error'} emailSendingStatus  - Status of email sending async thunk
 */

/**
 * @type {QuotationState}
 */
const initialState = {
  data: null,
  dataExternal: null,
  myQuotations: null,
  details: null,
  suggestions: {
    keyFilter: "seller",
    folio: [],
    seller: [],
    client: []
  },
  tempQuotation: null,
  externalSuggestions: {
    keyFilter: "seller",
    folio: [],
    seller: [],
    client: []
  },
  mySuggestions: {
    keyFilter: "client",
    folio: [],
    seller: [],
    client: []
  },
  quotationsOptions: [],
  response: null,
  summaryData: null,
  // client and sales fee selected to quotation
  client: null,
  salesFee: null,
  name: "",
  email: "",
  clientFees: null,
  emailSendingStatus: "idle"
}

export const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    reset: () => initialState,
    saveSummaryData: (state, action) => {
      state.summaryData = action.payload
    },
    updateQuotationSelected: (state, action) => {
      state.client = action.payload.client
      state.salesFee = action.payload.salesFee
      state.name = action.payload.name
      state.email = action.payload.email
      state.prospectId = action.payload.prospectId
    },
    setKeyFilter: (state, action) => {
      state.suggestions.keyFilter = action.payload
    },
    setMyQuotationsKeyFilter: (state, action) => {
      state.mySuggestions.keyFilter = action.payload
    },
    setExternalQuotationsKeyFilter: (state, action) => {
      state.externalSuggestions.keyFilter = action.payload
    },
    clearEmailStatus: (state) => {
      state.emailSendingStatus = "idle"
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveQuotation.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.tempQuotation = action.payload.data
      }
    })
    builder.addCase(getQuotations.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.suggestions = {
        folio: action.payload?.data?.meta?.suggestions,
        seller: action.payload?.data?.meta?.seller_suggestions,
        client: action.payload?.data?.meta?.client_suggestions,
        keyFilter: state.suggestions.keyFilter
      }
    })
    builder.addCase(getExternalQuotations.fulfilled, (state, action) => {
      state.dataExternal = action.payload.data
      state.externalSuggestions = {
        folio: action.payload?.data?.meta?.suggestions,
        seller: action.payload?.data?.meta?.seller_suggestions,
        client: action.payload?.data?.meta?.client_suggestions,
        keyFilter: state.externalSuggestions.keyFilter
      }
    })
    builder.addCase(getMyQuotations.fulfilled, (state, action) => {
      state.myQuotations = action.payload.data
      state.mySuggestions = {
        folio: action.payload?.data?.meta?.suggestions,
        seller: action.payload?.data?.meta?.seller_suggestions,
        client: action.payload?.data?.meta?.client_suggestions,
        keyFilter: state.mySuggestions.keyFilter
      }
    })
    builder.addCase(getQuotationDetails.fulfilled, (state, action) => {
      state.details = action.payload.data
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getClientFee.fulfilled, (state, action) => {
      state.clientFees = action.payload?.data
    })
    builder.addCase(getExternalSuggestions.fulfilled, (state, action) => {
      state.externalSuggestions = action.payload.data
    })
    builder.addCase(getMySuggestions.fulfilled, (state, action) => {
      state.mySuggestions = action.payload.data
    })
    builder.addCase(getQuotationsOptions.fulfilled, (state, action) => {
      state.quotationsOptions = action.payload.data
    })
    builder.addCase(sendQuotationByEmail.pending, (state) => {
      state.emailSendingStatus = "loading"
    })
    builder.addCase(sendQuotationByEmail.fulfilled, (state, action) => {
      state.response = action.payload.data
      state.emailSendingStatus = "success"
    })
    builder.addCase(sendQuotationByEmail.rejected, (state) => {
      state.response = null
      state.emailSendingStatus = "error"
    })
    builder.addCase(clearResponse.fulfilled, (state) => {
      state.response = null
    })
  }
})

export const {
  clearEmailStatus,
  reset,
  saveSummaryData,
  setExternalQuotationsKeyFilter,
  setKeyFilter,
  setMyQuotationsKeyFilter,
  updateQuotationSelected
} = quotationsSlice.actions
export default quotationsSlice.reducer
