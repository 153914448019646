// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

const urlBase = "api/modes_transport"

export const getModestransport = createAsyncThunk(
  "modesTransport/get",
  async (params) => {
    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const createModetransport = createAsyncThunk(
  "modesTransport/store",
  async (data, { dispatch }) => {
    const response = await api.post(`${urlBase}`, data)
    const url = `/catalogs/modes_transport`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      params,
      data: response.data
    }
  }
)

export const getModesTransportById = createAsyncThunk(
  "modesTransport/show",
  async (id) => {
    const response = await api.get(`${urlBase}/${id}`)
    return {
      data: response.data
    }
  }
)

export const editModestransport = createAsyncThunk(
  "modesTransport/edit",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`${urlBase}/${id}`, data)
    const url = `/catalogs/modes_transport`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      data: response.data
    }
  }
)

export const deleteModesTransport = createAsyncThunk(
    "modesTransport/delete",
    async (id, { dispatch }) => {
      const response = await api.delete(`${urlBase}/${id}`)
      const url = `/catalogs/modes_transport`
      dispatch(showModalSuccess({ message: response.data.message, url }))
      dispatch(getModestransport())
      return {
        data: response.data
      }
    }
  )

export const modesTransportSlice = createSlice({
  name: "modesTransport",
  initialState: {
    data: [],
    defaultValues: {},
    currentPage: 1,
    params: {},
    meta: {},
    total: 0
  },
  reducers: {
    cleanDefaultValues: (state) => {
      state.defaultValues = {}
    }
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getModestransport.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.currentPage = action.payload.data.current_page
      state.params = action.payload.params
      state.meta = action.payload.data.meta
      state.total = action.payload.data.meta.total
    })
    builder.addCase(createModetransport.fulfilled, (state, action) => {
      state.data = action.payload.data.data
    })
    builder.addCase(getModesTransportById.fulfilled, (state, action) => {
        const payload = action.payload.data.data
        state.data = action.payload.data.data
        state.defaultValues = {
          concepto: payload.concept
        }
      })
  }
})
export const { cleanDefaultValues } = modesTransportSlice.actions
export default modesTransportSlice.reducer
