// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalTypeError = createSlice({
  name: 'error_modal',
  initialState: {
    toggle: false,
    message: "",
    url: null
  },
  reducers: {
    showModalError: (state, action) => {
      state.toggle = true
      state.message = action.payload.message
      state.url = action.payload.url
    },
    hideModalError: (state) => {
      state.toggle = false
      state.message = ""
    }
  }
})

export const { showModalError, hideModalError } = modalTypeError.actions

export default modalTypeError.reducer
