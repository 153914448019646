import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getBlocks = createAsyncThunk('blocks/get', async () => {
  const response = await api.get(`api/blocks`)
  return {
    data: response.data
  }
})

const initialState = {
  blocks: []
}

const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBlocks.fulfilled, (state, action) => {
      state.blocks = action.payload.data
    })
  }
})

export const {setBlocks} = blocksSlice.actions
export default blocksSlice.reducer