// You can customize the template with the help of this file
import { ReactComponent as Logo } from "@src/assets/images/logo/orbe_logo.svg"
import { ReactComponent as MinimalLogo } from "@src/assets/images/logo/mono-logo.svg"

//Template config options
const themeConfig = {
  app: {
    appName: "Vuexy",
    appLogoImage: require("@src/assets/images/logo/orbe_logo.svg").default,
    minimalLogoImage: require("@src/assets/images/logo/mono-logo.svg").default
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    toast_time: 9000,
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export {Logo, MinimalLogo}
export default themeConfig
