// ** Redux Imports
// path: @src/views/Catalogs/Countries/store
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// ** Axios Imports
import api from "@src/api/config"
import { hideModalCustom } from "../../../../redux/modalTypeCustom"
import { toggletResetFilters } from "../../../../redux/filters"

/**
 * Retrieve coverage countries catalog, only countries already created
 */
export const getCountries = createAsyncThunk("getCountries", async (params) => {
  const response = await api.get(`api/countries`, {params})
  return {
    params,
    data: response.data
  }
})

export const getSuggestions = createAsyncThunk("getSuggestions", async () => {
  const response = await api.get(`api/countries/suggestions`)
  return {
    data: response.data
  }
})

export const updateCountry = createAsyncThunk(
  "countries/update",
  async ({id, data}, { dispatch }) => {
    const response = await api.put(`api/countries/${id}`, data)
    dispatch(toggletResetFilters())
    dispatch(hideModalCustom())
    if (response.data.status === "success") {
      dispatch(getCountries({ page: 1}))
      const url = "/catalogs/countries"
      dispatch(showModalSuccess({ message: "El estatus del país se ha actualizado con exitdo.", url })) 
    }
    return {
      data: response.data
    }
  }
)

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    data: [],
    suggestions: [],
    countries: [],
    providers: [],
    defaultValues: null,
    currentPage: 1,
    params: {},
    meta: {},
    total: 0
  },
  reducers: {
    resetCountries: (state) => {
      state.data = []
      state.countries = []
      state.defaultValues = null
      state.currentPage = 1
      state.params = {}
      state.meta = {}
      state.total = 0
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      if (action.payload?.params?.format === 'options') {
        state.countries = action.payload.data
      } else {
        state.data = action.payload.data
        state.currentPage = action.payload.data.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.total
      }
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
  }
})

export const {resetCountries} = countriesSlice.actions

export default countriesSlice.reducer
