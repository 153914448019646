// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// import { hideModalCustom } from "../../../../redux/modalTypeCustom"

const urlBase = "api/validities"

const initialState = {
    data: [],
    suggestions: [],
    // defaultValues: {},
    currentPage: 1,
    params: {}, // all filters
    meta: {},
    total: 0,
    rowsPerPage: 10,
    loading: false,
    validity: {},
    errors: {},
    validityOptions: [],
    sending: false
  }

export const getValidities = createAsyncThunk(
  "validities/get",
  async (params, {getState}) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getValidityById = createAsyncThunk(
  "validities/getById",
  async (id) => {
    const response = await api.get(`api/validities/${id}`)
    return {
      data: response.data
    }
  }
)

export const createValidities = createAsyncThunk("validities/store", async (payload = {}, {getState, dispatch, rejectWithValue}) => {
  const state = getState()
  const { productSelected } = state.products
  payload.producto = productSelected.value

  try {
    const response = await api.post(`${urlBase}`, payload)    
    // success handler
    if (response.data?.status === 'success') {
      if (payload.from === 'providers') dispatch(getValidities({ format: "options" }))
      dispatch(showModalSuccess(payload.from === 'providers' ? {message: response.data.message} : {message: response.data.message, url: '/catalogs/validities'}))
    }
  
    return {
      data: response.data
    }
  } catch (err) {
    if (err.response?.data?.errors) {
      return rejectWithValue(err.response)
    }
  }
})

export const getValiditiesSuggestion = createAsyncThunk(
  "validities/getSuggestion",
  async (params, {getState}) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get('api/validities/suggestions', { params })
    return {
      params,
      data: response.data
    }
  }
)

export const updateValidity = createAsyncThunk(
  "validities/update",
  async ({id, payload}, {dispatch, getState}) => {
    const state = getState()
    const { productSelected } = state.products
    payload.producto = productSelected.value
    
    const response = await api.put(`api/validities/${id}`, payload)

    // success handler
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({message: response.data.message, url: '/catalogs/validities'}))
    }

    return {
      data: response.data
    }
  }
)

export const deleteValidity = createAsyncThunk(
  "validities/destroy",
  async (id, {dispatch}) => {
    const response = await api.delete(`api/validities/${id}`)

    // success handler
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({message: response.data.message, url: '/catalogs/validities'}))
      dispatch(getValidities({page: 1}))
    }

    return {
      data: response.data
    }
  }
)

export const validitiesSlice = createSlice({
  name: "validities",
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getValidities.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValidities.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getValidities.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload?.params?.format === 'options') {
        state.validityOptions = action.payload.data
      } else {
        state.data = action.payload.data.data
        state.currentPage = action.payload.data.meta.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.meta.total
        state.rowsPerPage = action.payload.data.meta.per_page
      }

    })
    builder.addCase(getValiditiesSuggestion.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getValidityById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValidityById.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getValidityById.fulfilled, (state, action) => {
      state.loading = false
      state.validity = action.payload.data.data
    })
    builder.addCase(updateValidity.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(updateValidity.pending, (state) => {
      state.sending = true
    })
    builder.addCase(updateValidity.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(createValidities.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(createValidities.pending, (state) => {
      state.sending = true
    })
    builder.addCase(createValidities.rejected, (state, action) => {
      state.sending = false
      if (action.payload.status === 422) {
        state.errors = action.payload.data.errors
      }
    })
  }
})


export default validitiesSlice.reducer
