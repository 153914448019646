// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import fileDownload from 'js-file-download'
// ** Axios Imports
import api from "@src/api/config"

export const getBalanceSummary = createAsyncThunk(
  'balanceSummary/get',
  async (params) => {
    try {
      const response = await api.get(`api/balance/summary`, { params })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)

export const getBalanceSummarySuggestion = createAsyncThunk(
    'balanceSummarySuggestion/get',
    async (params) => {
      try {
        const response = await api.get(`api/balance/summary/suggestions`, { params })
        return {
          data: response.data,
          params
        }
      } catch (err) {
        // console.error(err)
      }
    }
  )

export const getBalanceReport = createAsyncThunk(
  'balanceReport/create',
  async ({ params, filename }) => {
    try {
      const response = await api.post(`api/balance/pdf_report`, params, { responseType: 'blob' })
      fileDownload(response.data, filename)
      return {
        data: response.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)

export const balancesSummarySlice = createSlice({
  name: "balance_summary",
  initialState: {
    // balance summary
    balance_summary: {
      current_balance: 0,
      due_balance: 0,
      uncollectible_balance: 0,
      url: null
    }, // all row data
    balance_summary_status: 'idle',
    suggestions: [],
    suggestions_status: 'idle',
    report_status: 'idle',
    search_key: 'client',
    search_label: 'Cliente',
    search_value: null,
    // range
    from: null,
    to: null
  },
  reducers: {
    resetSummary: (state) => {
      state.balance_summary = {
        current_balance: 0,
        due_balance: 0,
        uncollectible_balance: 0,
        url: null
      }
    },
    changeFilter: (state, action) => {
      state.search_key = action.payload.search_key
      state.search_label = action.payload.search_label
      state.search_value = action.payload.search_value
    }
  },
  extraReducers: (builder) => {
    // Current balance
    builder.addCase(getBalanceSummary.pending, (state) => {
      state.balance_summary_status = "loading"
    })
    builder.addCase(getBalanceSummary.fulfilled, (state, action) => {
      // console.debug("params: ", action)
      // update filters
      if (action.payload?.params) {
        state.search_value = action.payload.params.search_value
        state.from = action.payload.params.from
        state.to = action.payload.params.to
      }
      // update data
      state.balance_summary_status = "succeeded"
      state.balance_summary = action?.payload?.data
    })
    builder.addCase(getBalanceSummary.rejected, (state) => {
      state.balance_summary_status = "failed"
    })
    // suggestions
    builder.addCase(getBalanceSummarySuggestion.pending, (state) => {
      state.suggestions_status = "loading"
    })
    builder.addCase(getBalanceSummarySuggestion.fulfilled, (state, action) => {
      state.suggestions_status = "succeeded"
      state.suggestions = action.payload?.data
    })
    builder.addCase(getBalanceSummarySuggestion.rejected, (state) => {
      state.suggestions_status = "failed"
    })
    // reports
    builder.addCase(getBalanceReport.pending, (state) => {
      state.report_status = "loading"
    })
    builder.addCase(getBalanceReport.fulfilled, (state) => {
      state.report_status = "succeeded"
    })
    builder.addCase(getBalanceReport.rejected, (state) => {
      state.report_status = "failed"
    })
  }
})

export const {
  resetSummary,
  changeFilter,
  balance_summary_status
} = balancesSummarySlice.actions

export default balancesSummarySlice.reducer
